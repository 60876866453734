// Project Layout Styles
// ---------------------

html {
	-webkit-font-smoothing: antialiased;
}

body {
	//background-color: $brand-green;
}

figure {
	margin-bottom: 20px;

	img {
		@include img-responsive;
	}
}

picture {
	display: block;
}

.uppercase {
	text-transform: uppercase;
}


// Selection
// ---------

::-moz-selection {
	background: $brand-primary;
	color: #fff;
	text-shadow: none;
}

::selection {
	background: $brand-primary;
	color: #fff;
	text-shadow: none;
}

// Page
// ----

#page {
	background-color: $body-bg;
}

// Global nav styles
// -----------------

nav ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;

	a {
		text-decoration: none;

		&:hover, &:focus {
			text-decoration: none;
		}
	}
}


// Mobile Navigation
// -----------------

nav#mobile {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 10;

	&:before {
		content: '';
		display: block;
		position: fixed;
		top: 67px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: 3;
		background: $brand-green;
		@include gradient-vertical($brand-green, $brand-blue);
	}
	> a {
		@include square(40px);
		position: absolute;
		top: 15px;
		right: 15px;
		border-radius: 50%;
		background-color: $brand-primary;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		@include box-shadow(0 1px 5px rgba(0, 0, 0, 0.25));
		z-index: 10;
		@include transition(all 0.5s ease);

		i.button {
			position: absolute;
			right: 11px;
			top: 13px;
			width: 18px;
			height: 3px;
			margin: 6px 0;
			background-color: $body-bg;
			@include transition(all 0.5s ease);

			&:before, &:after {
				content: '';
				position: absolute;
				display: block;
				width: 18px;
				height: 3px;
				background-color: $body-bg;
				@include transition(all 0.5s ease);
			}
			&:before {
				top: -6px;
			}
			&:after {
				top: 6px;
			}
		}
	}
	> .container {
		padding: 0;
		margin: 0;
		position: fixed;
		overflow-x: auto;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 5;

		ul {
			li {
				a {
					display: block;
					padding: 10px 15px;
					font-size: 18px;
					font-weight: 400;
					color: $body-bg;
					border-bottom: 2px solid rgba($brand-green, 0.5);
					position: relative;

					&:before {
						position: absolute;
						content: '';
						right: 10px;
						top: 50%;
						margin-top: -10px;
						@include square(20px);
						border-radius: 50%;
						background-color: $brand-green;
					}
					&:after {
						position: absolute;
						right: 16px;
						top: 50%;
						margin-top: -9px;
						@include fa-add-icon($fa-var-angle-right);
						color: $body-bg;
						opacity: 0.75;
					}
					&:hover {
						color: $body-bg;

						&:before {
							background-color: rgba(0, 0, 0, 0.1);
						}
						&:after {
							opacity: 1;
						}
					}
				}
				ul {
					li {
						position: relative;

						&:before {
							position: absolute;
							top: 15px;
							left: 15px;
							@include fa-add-icon($fa-var-level-up);
							@include rotate(90deg);
							color: rgba(0, 0, 0, 0.2);
						}
					}
				}
			}
		}
		> ul {
			margin: 90px 20px 20px;
			background-color: rgba(0, 0, 0, 0.1);

			> li {
				> a {
				}
				&:hover, &.active, &.current {
					> a {

						background-color: rgba(0, 0, 0, 0.1);
					}
				}
				> ul {
					> li {
						> a {
							padding-left: 35px;
						}
						&:hover, &.active, &.current {
							> a {
								background-color: rgba(0, 0, 0, 0.1);
							}
						}
						> ul {
							> li {
								&:before {
									left: 40px;
								}
								> a {
									padding-left: 60px;
									padding-right: 20px;
								}
								&:hover, &.active, &.current {
									> a {
										background-color: rgba(0, 0, 0, 0.1);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

body.nav-active {
	nav#mobile {
		&:before, > .container {
			opacity: 0;
			visibility: hidden;
			@include transition(all 0.5s ease);
			@include scale(1.15);
		}
	}
	&.scrolled-mobile, &.mobile-opened {
		nav#mobile > a {
			top: 15px;
		}
	}
}

body.mobile-opened {
	position: relative;
	overflow: hidden;

	nav#mobile:before, nav#mobile > .container {
		visibility: visible;
		opacity: 1;
		@include scale(1);
	}
	nav#mobile {
		> a {
			background-color: $brand-primary;

			i.button {
				background: transparent;

				&:before, &:after {
					top: 0;
				}
				&:before {
					@include rotate(-45deg);
				}
				&:after {
					@include rotate(45deg);
				}
			}
		}
	}
}


// Header
// ------

#header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	padding: 20px 0;
	background: $white-base;
	box-shadow: 0 2px 5px rgba($black-base, 0.15);
	z-index: 3;
	transition: all 0.33s ease;
	
	.brand {
		margin: 0;
		position: relative;
		display: block;
		opacity: 1;
		@include text-hide;
		@include size($brand-width, $brand-height);
		background-size: $brand-width, $brand-height;
		@include img-retina('../Images/logo-millenium.png', '../Images/logo-millenium@2x.png', $brand-width, $brand-height);
		background-repeat: no-repeat;
		z-index: 1;
		transition: all 0.33s ease;

		&:hover {
			opacity: 0.5;
		}
	}
}

body.scrolled, body.scrolled-mobile {
	#header {
		padding: 10px 0;
		background: rgba($white-base, 0.9);
		
		.brand {
			@include size($brand-width / 1.5, $brand-height / 1.5);
			background-size: $brand-width / 1.5, $brand-height / 1.5;
			@include img-retina('../Images/logo-millenium.png', '../Images/logo-millenium@2x.png', $brand-width / 1.5, $brand-height / 1.5);
		}
	}
	nav#main {
		padding: 9px 0;
	}
}

// Nav Main
// --------

nav#main {
	padding: 19px 0;
	transition: all 0.33s ease;
	
	> ul {
		> li {
			float: left;
			margin: 0 15px;
			
			> a {
				font-size: 20px;
				text-transform: uppercase;
				color: $brand-green;
				font-weight: bold;
				transition: color 0.33s ease;
			}
			&.active, &.current, &:hover {
				> a {
					color: $brand-primary;
				}
			}
			&.first {
				margin-left: 0;
			}
			&.last {
				margin-right: 0;
			}
		}
	}
}


// Stage
// -----

#page.subpage {
	#stage {
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			content: '';
			display: block;
			@include gradient-horizontal($brand-green, $brand-blue);
			z-index: 1;
			opacity: 0.85;
		}
		.stage-content {
			text-align: left;
			
			.container {
				padding-right: 33%;
			}
		}
	}
}

#page.landingpage {
	#stage {
		.stage-content {
			text-align: center;
			@include gradient-horizontal(rgba($brand-green, 0.85), rgba($brand-blue, 0.85));
		}
	}
}

#stage {
	position: relative;
	height: 400px;
	overflow: hidden;
	
	&.bg-img {
		background-size: cover;
		background-position: center top;
	}
	.stage-content {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 40px 0;
		color: $white-base;
		z-index: 2;

		h1, h2, p {
			color: $white-base;
		}
		h2 {
			font-size: 40px;
			font-weight: bold;
			margin-bottom: 10px;
			line-height: 1.15;
		}
		p {
			font-size: 30px;
			font-weight: 300;
			line-height: 1.25;
			margin-bottom: $line-height-computed;
		}
	}
	.stage-arrow {
		color: $white-base;
		display: inline-block;
		@include square(40px);
		border: 2px solid $white-base;
		text-align: center;
		border-radius: 50%;
		z-index: 3;
		cursor: pointer;
		transition: opacity 0.33s ease;
		opacity: 1;
		
		.fa {
			font-size: 32px;
			line-height: 1.2;
		}
		&:hover {
			opacity: 0.5;
		}
	}
}

// Content
// -------

#content {
	.panel-heading {
		padding: 0;

		a {
			display: block;
			padding: 10px 15px;
			font-weight: normal;
			text-decoration: none;
			color: $brand-primary;
			background: lighten($brand-primary, 7%);

			&:after {
				float: right;
				@include fa-add-icon($fa-var-angle-up);
			}
			&.collapsed {
				color: $brand-primary;
				background: $gray-lighter;

				&:after {
					@include fa-add-icon($fa-var-angle-down);
				}
				&:hover {
					color: $brand-primary;
					background: lighten($brand-primary, 7%);
				}
			}
		}
	}
	.panel-body {
		padding-bottom: 5px;
	}
	.nav-tabs > li > a {
		font-weight: 700;
	}
	.tab-content {
		padding: $panel-body-padding;
		padding-bottom: 5px;
		margin-bottom: $line-height-computed;
		border: 1px solid $nav-tabs-border-color;
		border-top: none;
	}
	.form-navigation ul {
		padding-left: 0;
		list-style: none;
	}
	.typo3-neos-nodetypes-text {
		table {
			@extend .table;
		}
	}
	.fullwidth-container {
		padding: 100px 0;

		&.small {
			padding: 40px 0;
		}
		&.gray {
			background-color: $gray-lighter;
		}
		&.bg-img {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
	}
}


// Footer
// ------

#footer {
	padding: 30px 0;
	color: mix($brand-green, $white-base, 25%);
	background-color: $brand-green;
	
	a {
		color: mix($brand-green, $white-base, 25%);
		transition: color 0.33s ease;
		
		&:hover {
			color: $white-base;
			text-decoration: none;
		}
	}
	address {
		text-align: center;
		margin: 0;
		
		br {
			display: none;
		}
		> span {
			display: inline-block;
			margin: 15px;
			
			.fa {
				margin-right: 10px;
				font-size: 28px;
				vertical-align: top;
				color: $white-base;
				
				&.fa-mobile {
					font-size: 40px;
					position: relative;
					top: -7px;
				}
			}
		}
	}
	hr {
		border-color: rgba($black-base, 0.1);
		margin-bottom: $line-height-computed * 1.5;
	}
}

nav#foot {
	text-align: right;
	
	ul {
		display: inline-block;
		@include clearfix;
		
		li {
			float: left;
			margin: 0 10px;
			
			&.first {
				margin-left: 0;
			}
			&.last {
				margin-right: 0;
			}
			&.active a {
				color: $white-base;
			}
		}
	}
}

nav#foot, .copyright {
	font-size: 90%;
}

// Gototop
// -------

body.totop {
	a.totop {
		bottom: 40px;
		opacity: 1;
	}
}

a.totop {
	opacity: 0;
	position: fixed;
	bottom: -40px;
	right: 40px;
	@include square(40px);
	border-radius: 50%;
	background: rgba($brand-red, 0.75);
	text-align: center;
	transition: background-color 0.33s ease, border 0.33s ease, opacity 0.5s ease, bottom 0.5s ease;

	.fa {
		font-size: 36px;
		color: $white-base;
		transition: color 0.33s ease, opacity 0.33s ease;
	}
	&:hover {
		background: rgba($brand-red, 1);
	}
}


// Browserupgrade
// -----------

.browserupgrade {
	margin: 0;
	padding: 0.2em 1em;
	text-align: center;
	color: $text-color;
	background: $gray-lighter;
	border-bottom: 1px solid darken($gray-lighter, 5%);
	z-index: $zindex-navbar-fixed + 1;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	font-size: 0.7777777778em;

	+ noscript.no-js-info {
		top: 20px;
	}
	+ #page {
		padding-top: 20px;
	}
}

// No js loaded
// ------------

.no-js {
	noscript.no-js-info {
		@extend .browserupgrade;
	}
	.browserupgrade {
		+ #page {
			padding-top: 40px \9;
		}
	}
}