// Overwrite variables
@import "variables";

// Fonts
// -----

// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Lato:300,300italic,400,400italic,700,700italic);

// Fontawesome
@import "vendors/fontawesome/variables";
@import "vendors/fontawesome/mixins";
@import "vendors/fontawesome/path";
@import "vendors/fontawesome/core";
@import "vendors/fontawesome/larger";
@import "vendors/fontawesome/fixed-width";
@import "vendors/fontawesome/list";
@import "vendors/fontawesome/bordered-pulled";
@import "vendors/fontawesome/animated";
@import "vendors/fontawesome/rotated-flipped";
@import "vendors/fontawesome/stacked";
@import "vendors/fontawesome/icons";


// Custom Vars & Mixins
// --------------------
@import "mixins";


// Twitter Bootstrap
// -----------------
// Core variables and mixins
@import "vendors/twitter-bootstrap-sass/variables";
@import "bootstrap/mixins";
// Reset and dependencies
@import "vendors/twitter-bootstrap-sass/normalize";
@import "vendors/twitter-bootstrap-sass/print";
@import "vendors/twitter-bootstrap-sass/glyphicons";
// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "vendors/twitter-bootstrap-sass/code";
@import "vendors/twitter-bootstrap-sass/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";


// Components
@import "vendors/twitter-bootstrap-sass/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "vendors/twitter-bootstrap-sass/input-groups";
@import "vendors/twitter-bootstrap-sass/navs";
@import "vendors/twitter-bootstrap-sass/navbar";
@import "vendors/twitter-bootstrap-sass/breadcrumbs";
@import "bootstrap/pagination";
@import "vendors/twitter-bootstrap-sass/pager";
@import "vendors/twitter-bootstrap-sass/labels";
@import "vendors/twitter-bootstrap-sass/badges";
@import "vendors/twitter-bootstrap-sass/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "vendors/twitter-bootstrap-sass/progress-bars";
@import "vendors/twitter-bootstrap-sass/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "vendors/twitter-bootstrap-sass/responsive-embed";
@import "bootstrap/wells";
@import "vendors/twitter-bootstrap-sass/close";
// Components w/ JavaScript
@import "bootstrap/modals";
@import "vendors/twitter-bootstrap-sass/tooltip";
@import "vendors/twitter-bootstrap-sass/popovers";
@import "bootstrap/carousel";
// Utility classes
@import "vendors/twitter-bootstrap-sass/utilities";
@import "vendors/twitter-bootstrap-sass/responsive-utilities";


// Custom
// ------
@import "layout";
@import "responsive";
//@import "cms/neos-content";
//@import "cms/typo3-content";