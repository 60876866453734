// Responsive Styles
// -----------------

// Responsive: Large desktop and up
// --------------------------------


@media (min-width: 1200px) {

	
}


@media (min-width: 992px) and (max-width: 1199px) {

	body {
		font-size: 16px;

		&.totop a.totop {
			bottom: 20px;
		}
	}
	a.totop {
		right: 20px;
	}
	h1, .h1 {
		font-size: 32px;
	}
	h2, .h2 {
		font-size: 28px;
	}
	h3, .h3 {
		font-size: 26px;
	}
	h4, .h4 {
		font-size: 22px;
	}
	h5, .h5 {
		font-size: 20px;
	}
	h6, .h6 {
		font-size: 18px;
	}
	nav#main {
		padding: 21px 0;

		> ul > li > a {
			font-size: 18px;
		}
	}
	#stage .stage-content {
		h2 {
			font-size: 32px;
		}
		p {
			font-size: 24px;
		}
	}
	#page.subpage {
		#stage {
			height: 360px;
			
			.stage-content .container {
				padding-right: 25%;
			}
		}
	}

}

// Responsive: Tablet to desktop
// --------------------------------------------------

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {


}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {


}

@media (min-width: 768px) and (max-width: 991px) {

	body {
		font-size: 15px;

		&.totop a.totop {
			bottom: 20px;
		}
	}
	a.totop {
		right: 20px;
	}
	h1, .h1 {
		font-size: 28px;
	}
	h2, .h2 {
		font-size: 24px;
	}
	h3, .h3 {
		font-size: 22px;
	}
	h4, .h4 {
		font-size: 20px;
	}
	h5, .h5 {
		font-size: 18px;
	}
	h6, .h6 {
		font-size: 16px;
	}
	#header {
		.brand {
			@include size($brand-width / 1.25, $brand-height / 1.25);
			background-size: $brand-width / 1.25, $brand-height / 1.25;
			@include img-retina('../Images/logo-millenium.png', '../Images/logo-millenium@2x.png', $brand-width / 1.25, $brand-height / 1.25);
		}
	}
	nav#main {
		padding: 15px 0;

		> ul > li {
			margin: 0 10px;

			> a {
				font-size: 16px;
			}
			&.first {
				margin-left: 0;
			}
			&.last {
				margin-right: 0;
			}
		}
	}
	#stage .stage-content {
		padding: 30px 0;

		h2 {
			font-size: 28px;
		}
		p {
			font-size: 18px;
		}
	}
	#page.subpage {
		#stage {
			height: 300px;

			.stage-content .container {
				padding-right: 25%;
			}
		}
	}
	#footer {
		.copyright, nav#foot {
			text-align: center;
		}
		nav#foot {
			font-size: inherit;
			margin-bottom: $line-height-computed / 2;
		}
	}

}

@media (max-width: 812px) {

	.no-js noscript.no-js-info {
		+ #page {
			padding-top: 60px;
		}
	}

}

@media (max-width: 767px) {
	
	body {
		font-size: 14px;
		
		&.totop a.totop {
			bottom: 20px;
		}
	}
	a.totop {
		right: 20px;
	}
	h1, .h1 {
		font-size: 28px;
	}
	h2, .h2 {
		font-size: 24px;
	}
	h3, .h3 {
		font-size: 22px;
	}
	h4, .h4 {
		font-size: 20px;
	}
	h5, .h5 {
		font-size: 18px;
	}
	h6, .h6 {
		font-size: 16px;
	}
	figure {
		display: inline-block;
	}
	#header {
		padding: 10px 0;
		
		.brand {
			@include size($brand-width / 1.5, $brand-height / 1.5);
			background-size: $brand-width / 1.5, $brand-height / 1.5;
			@include img-retina('../Images/logo-millenium.png', '../Images/logo-millenium@2x.png', $brand-width / 1.5, $brand-height / 1.5);
		}
	}
	#stage .stage-content {
		padding: 20px 0;

		h2 {
			font-size: 28px;
		}
		p {
			font-size: 18px;
			margin-bottom: $line-height-computed / 2;
		}
	}
	#page.subpage {
		#stage {
			height: 300px;

			.stage-content .container {
				padding-right: 15px;
			}
		}
	}
	nav#main {
		padding: 15px 0;

		> ul > li {
			margin: 0 10px;

			> a {
				font-size: 16px;
			}
			&.first {
				margin-left: 0;
			}
			&.last {
				margin-right: 0;
			}
		}
	}
	#footer {
		.copyright, nav#foot {
			text-align: center;
		}
		nav#foot {
			font-size: inherit;
			margin-bottom: $line-height-computed / 2;
		}
	}

}

@media (max-width: 560px) {

	

}

@media (max-width: 520px) {

	.browsehappy {
		+ #page {
			padding-top: 52px;
		}
	}

}

@media (max-width: 480px) {

	body {
		font-size: 13px;
	}
	h1, .h1 {
		font-size: 25px;
	}
	h2, .h2 {
		font-size: 22px;
	}
	h3, .h3 {
		font-size: 19px;
	}
	h4, .h4 {
		font-size: 17px;
	}
	h5, .h5 {
		font-size: 15px;
	}
	h6, .h6 {
		font-size: 14px;
	}
	nav#mobile ul li {
		ul li:before {
			top: 14px;
		}
		a {
			font-size: 17px;

			&:after {
				margin-top: -8px;
			}
		}
	}

}

@media screen and (max-device-width: 480px) and (orientation: landscape) {



}


@media (max-width: 420px) {

	nav#mobile ul li {
		ul li:before {
			top: 13px;
		}
		a {
			font-size: 15px;

			&:after {
				margin-top: -8px;
			}
		}
	}

}

@media (max-width: 360px) {

	nav#mobile ul li {
		ul li:before {
			top: 12px;
		}
		a {
			font-size: 13px;

			&:after {
				margin-top: -7px;
				right: 17px;
			}
		}
	}


}