//
// Wells
// --------------------------------------------------


// Base class
.well {
  min-height: 20px;
  padding: 20px;
  margin-bottom: 30px;
  background-color: $well-bg;
  
  hr {
    border-top-color: darken($gray-lighter, 10%);
  }
  blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,.15);
  }
  p, ul, blockquote, h1, h2, h3, h4, h5, h6, table, address {
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  &.well-white {
    background-color: $white-base;
    @include box-shadow(0 0 5px rgba(0,0,0,.1));
  }
  &.well-primary {
    background-color: lighten($brand-primary, 36%);
    border-color: $brand-primary;
    color: darken($brand-primary, 10%);
  }
  &.well-success {
    background-color: lighten($brand-success, 40%);
    border-color: $brand-success;
    color: darken($brand-success, 10%);
  }
  &.well-info {
    background-color: lighten($brand-info, 35%);
    border-color: $brand-info;
    color: darken($brand-info, 10%);
  }
  &.well-warning {
    background-color: lighten($brand-warning, 35%);
    border-color: $brand-warning;
    color: darken($brand-warning, 10%);
  }
  &.well-danger {
    background-color: lighten($brand-danger, 35%);
    border-color: $brand-danger;
    color: darken($brand-danger, 10%);
  }
  &.well-primary {
    background-color: lighten($brand-primary, 35%);
    border-color: $brand-primary;
    color: darken($brand-primary, 10%);
  }
}

// Sizes
.well-lg {
  padding: 30px;
  //border-radius: $border-radius-large;
}
.well-sm {
  padding: 10px 15px;
  //border-radius: $border-radius-small;
  
  hr {
    margin-top: $line-height-computed / 2;
    margin-bottom: $line-height-computed / 2;
  }
}