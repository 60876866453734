// Custom Mixin
// Added by Andreas Walter
// aw@sota-studio.de
// -----------------------

@mixin fa-add-icon($fa-var-name) {
	@include fa-icon;
	content: "#{$fa-var-name}";
}

@mixin translateZ($transform) {
	-webkit-transform: translateZ($transform);
	transform: translateZ($transform);
}

@mixin scale3d($x, $y, $z) {
	-webkit-transform: scale3d($x, $y, $z);
	-ms-transform: scale3d($x, $y, $z);
	-o-transform: scale3d($x, $y, $z);
	transform: scale3d($x, $y, $z);
}


// =============================================
// Mixin - Breakpoint
// =============================================

@mixin bp($feature, $value) {
	$media: only screen; // Set global device param

	@media #{$media} and ($feature: $value) {
		@content;
	}
}
