//
// Project variables
// --------------------------------------------------

$black-base:            						#000;
$gray-base:             						$black-base;
$gray-darker:           						#1a1a1a;
$gray-dark:             						#2a2a2a;
$gray:                  						#4a4a4a;
$gray-light:            						#9a9a9a;
$gray-lighter:          						#f2f2f2;
$white-base:         							#fff;

$brand-red:										#e6215a;
$brand-green:									#3fb498;
$brand-blue:									#1dbadf;

$brand-primary:         						$brand-red;
$brand-secondary:         						$brand-green;
$brand-success:         						$brand-green;
$brand-info:            						$brand-blue;
$brand-warning:         						#f0ad4e;
$brand-danger:          						$brand-red;

$text-color:            						$gray;
$link-color:            						$brand-primary;
$link-hover-color:      						$brand-blue;

$fa-font-path:        							"../Fonts/fontawesome/";

$brand-width:									310px;
$brand-height:									70px;

$bg-body:										$white-base;
$body-bg:										$bg-body;
$hr-border:										$gray-lighter;

$font-family-sans-serif:						"Lato", sans-serif;

$font-size-base:								18px;
$font-size-large:								24px;

$font-size-h1:									floor(($font-size-base * 2));
$font-size-h2:									floor(($font-size-base * 1.75));
$font-size-h3:									ceil(($font-size-base * 1.5));
$font-size-h4:									ceil(($font-size-base * 1.33));
$font-size-h5:									ceil(($font-size-base * 1.2));
$font-size-h6:									ceil(($font-size-base * 1.1));

$padding-base-vertical:							8px;
$padding-base-horizontal:						15px;

$padding-large-vertical:						12px;
$padding-large-horizontal:						20px;

$padding-small-vertical:						8px;
$padding-small-horizontal:						10px;

$padding-xs-vertical:							1px;
$padding-xs-horizontal:							6px;

$line-height-base:								1.6;

$headings-font-family:							$font-family-sans-serif;
$headings-font-weight:							bold;
$headings-line-height:							1.25;
$headings-color:								$brand-primary;

$btn-font-weight:								bold;
$btn-border-width:								2px;
$btn-border-radius-base:						0;
$btn-border-radius-small:						0;
$btn-border-radius-large:						0;

$btn-default-color:								$gray-light;
$btn-default-bg:								transparent;
$btn-default-border:							$gray-light;

$btn-primary-color:								$brand-primary;
$btn-primary-bg:								transparent;
$btn-primary-border:							$brand-primary;

$btn-success-color:								$brand-success;
$btn-success-bg:								transparent;
$btn-success-border:							$brand-success;

$btn-info-color:								$brand-info;
$btn-info-bg:									transparent;
$btn-info-border:								$brand-info;

$btn-warning-color:								$brand-warning;
$btn-warning-bg:								transparent;
$btn-warning-border:							$brand-warning;

$btn-danger-color:								$brand-danger;
$btn-danger-bg:									transparent;
$btn-danger-border:								$brand-danger;

$input-bg:										$white-base;
$input-border:									$gray-lighter;
$input-color-placeholder:						$gray-light;
$input-border-radius:							0;
$input-border-radius-small:						0;
$input-border-radius-large:						0;

$modal-inner-padding:							20px;
$modal-title-padding:							20px;
$modal-backdrop-bg:								$brand-green;
$modal-backdrop-opacity:						.9;
$modal-lg:										960px;
$modal-md:										670px;
$modal-sm:										380px;

$panel-border-radius:							0;
$panel-body-padding:							20px;

$thumbnail-padding:								5px;
$thumbnail-border-radius:						0;

$pagination-color: 								$text-color;
$pagination-hover-color: 						$link-color;

$carousel-control-opacity:						0.75;

$carousel-indicator-active-bg:					$brand-primary;

$well-bg:										$white-base;

$nav-tabs-active-link-hover-color:				$brand-primary;

$table-cell-padding:							7px 15px;
$table-condensed-cell-padding:					5px 9px;
$table-bg-accent:								$gray-lighter;
$table-bg-hover:								$gray-lighter;
$table-border-color:							$gray-lighter;

$tooltip-bg:									$brand-primary;
$tooltip-opacity:								.85;
$tooltip-arrow-width:							7px;
$tooltip-arrow-color:							$brand-primary;

$badge-bg:										$gray-lighter;
$badge-color:									lighten($gray-light, 15%);

$blockquote-small-color:						$brand-green;